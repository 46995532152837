.sidebar_inner {
    width: 100%;
    /* background: #10163a; */
    background: white;
    border-radius: 0;
    border: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: top;
    height: 100vh;
}

.sidebar_inner>ul>li>a:hover span{
    color: #3661dc;
    font-weight: 600;
}

a.active .nav_icon svg path, .sidebar_inner>ul>li>a:hover svg path {
    /* stroke: #fff; */
    fill: #4759f7;
}

.sidebar_outer {
    vertical-align: top;
    width: 280px;
    -webkit-box-shadow: 1px 0 20px rgb(0 0 0 / 8%);
    box-shadow: 1px 0 20px rgb(0 0 0 / 8%);
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0px;
    z-index: 999;
    transition: all .4s cubic-bezier(.25, .8, .25, 1);
}

.sidebar_inner>ul>li>a.active span {
    color: #3661dc;
}

.sidebar_inner>ul>li>a.active {
    font-weight: bold;
    color: #3661dc;
    /* background: linear-gradient(118deg,#3661dc,rgb(255 121 201));
border-radius: 7px;
box-shadow: 0 0 10px 1px rgb(33 183 175);
color: #fff; */
}

.sidebar_inner>ul>li>a.active::before {
    position: absolute;
    height: 78%;
    width: 5px;
    content: "";
    background: #4759f7;
    left: -10px;
    top: 6px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.body_toggle .site_logo .logo_icon {
    margin: auto;
}

.body_toggle .site_logo .logo_icon img {
    margin: 0;
}

.body_toggle div.sidebar_inner ul>li>a {
    padding-left: 13px;
}

.sidebar_inner ul {

    padding: 10px;
}

.sidebar_inner ul>li>a {
    /* color: #c2c6dc; */
    /* color: #242833;
    display: block;
    font-size: 12px;
    line-height: 26px;
    padding: 10px 15px;
    margin: 3px 0px;
    position: relative;
    text-decoration: none;
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden; */
}

div.sidebar_inner ul>li>a {
    color: #989fb3;
    display: inline-block;
    font-size: 12px;
    line-height: 26px;
    padding: 10px 15px;
    margin: 3px 0px;
    position: relative;
    width: 100%;
    text-decoration: none;
    display: flex;
}

.sidebar_inner ul>li>a>span {
    display: inline-block;
    margin-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
}

.site_logo img {
    width: 150px;
}

.site_logo {
    padding: 10px 15px;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 60px;
}

.site_logo svg {
    position: absolute;
    right: 15px;
    top: 18px;
    /* color: #fff; */
    font-size: 22px;
    fill: black;

}

.sidebar_outer.toggle {
    width: 70px;
    transition: .4s cubic-bezier(.25, .8, .25, 1);
}

.site_logo .logo_icon img {
    width: 30px;
    margin-right: 10px;
}

span.logo_text img {
    width: 90px;
    filter: invert(1);
}

.sidebar_outer.toggle span.logo_text img {
    display: none;
}

.sidebar_outer.toggle .site_logo svg {
    right: -34px;
    top: 20px;

}

.sidebar_outer.toggle .site_logo span {
    display: block;
}

/* media query starts here */
@media(max-width:991px) {
    .sidebar_outer {
        width: 60px;
    }

    .content_outer {
        margin-left: 66px;
    }

    span.logo_icon {
        display: none;
    }

    span.logo_text {
        display: none;
    }

    .sidebar_outer.toggle {
        width: 210px;
    }

    .sidebar_outer.toggle span.logo_icon {
        display: none;
    }

    .sidebar_outer.toggle span.logo_text {
        display: none;
    }

    body.body_toggle .content_outer {
        margin-left: 220px;
    }
}