ul.multistep_header {
    max-width: 950px;
    margin: auto;
    width: 100%;
    display: flex;
    text-align: center;
}

ul.multistep_header li {
    width: 100%;
}

p.step_heading {
    font-size: 12px;
    color: #bbc0ce;
}

p.number {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: #ddd;
    line-height: 30px;
    font-size: 16px;
    color: #fff;
    border-radius: 50%;
    z-index: 1;
    position: relative;
}

ul.multistep_header li {
    position: relative;
}

ul.multistep_header li::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    top: 15px;
    background: #d4d8e3;
    left: 50%;
}

ul.multistep_header li:last-child::before {
    display: none;
}

.active_form p.number {
    background: #7209b7;
    font-weight: bold;
}

.active_form p.step_heading {
    color: #7209b7;
    font-weight: bold;
}