/* goals */
.goals_card ul {
    display: flex;
    gap: 10px;
}

.input_wrap_border {
    border: 1px solid red;
}

.goals_card li {
    border-bottom: 3px solid #f978c8;
    background: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 7px;
    width: 100%;
}

.goals_card li:nth-child(2) {
    border-color: #00eacb;
}

.goals_card li:nth-child(3) {
    border-color: #4759f7;
}

.goals_card li:nth-child(4) {
    border-color: #7209b7;
}

.nodata {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
}

.reportOverflow {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.metricsFlex {
    display: flex;
    justify-content: space-between;
}

.multiSelectContainer {
    position: static !important;
    text-align: left;
    width: 100%;
}

.btnMetrics {
    width: 100px;
    height: 40px;
    flex-grow: 0;
    border-radius: 100px;
    background-color: #4759f7;
    border: transparent;
    color: #fff;
    font-weight: 700;
}

.no_data_found img {
    width: 314px;
    margin: auto;
}

select#formBasicEmail:focus {
    box-shadow: unset;
}

.no_data_found {
    padding: 50px 20px;
    min-height: 350px;
    align-items: center;
}

.goals_card li:nth-child(6) {
    border-color: #00eacb;
}

.no_data_found img {
    width: 314px;
    margin: auto;
}

.no_data_found {
    padding: 50px 20px;
}

.goals_card p {
    margin-bottom: 6px;
}

.goals_card h4 {
    font-weight: 700;
}

/* goals */
.metrics ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.metrics>ul>li {
    flex: unset !important;
    margin-bottom: 15px;
}

.input_wrap {
    background: #fff;
    border-radius: 6px;
    position: relative;
}

.input_wrap_multiSelect {
    background: #fff;
    border-radius: 6px;
    position: relative;
}


.input_wrap .form-select {
    /* height: 48px !important; */
}

.input_wrap img.custom_chevron_down {
    position: absolute;
    right: 8px;
    top: 14px;
}

.resetBtns.report_actions .btn-outline-success {
    width: 220px;
    height: 40px;
    flex-grow: 0;
    padding: 11px 73.9px 12px 75.4px;
    border-radius: 100px;
    background-color: #4759f7;
    border: transparent;
    color: #fff;
    font-weight: 700;
}

.content .metrics h2 {
    font-size: 24px;
    font-weight: 700;
    color: #000;
}

.resetBtns.report_actions {
    border-top: 1px solid #ddd;
    margin-top: 10px;
    text-align: right
}

.resetBtns.report_actions button.btn.btn-outline-danger ,.modal-body .cancel_cm_btn{
    background: transparent;
    border: transparent;
    color: #4759f7;
    font-weight: bold;
}

.status_dropdown.show.dropdown .dropdown-menu {
    inset: unset !important;
    transform: unset !important;
    bottom: -14px !important;
    z-index: 1;
}

.card.report__table td {
    padding: 10px;
}

.card.report__table th {
    background: #ddc6eb;
    color: #242833;
    font-weight: 700;
    font-size: 12px;
    padding: 10px;
}

.report__table button.expo_button {
    font-size: 12px;
    line-height: 1;
    border: 1px solid #28a745;
    background: #28a745;
    padding: 0.5rem 1.5rem;
    border-radius: 0.25rem;

    color: #fff;
}

button.expo_button {
    background: transparent;
    font-size: 12px;
    line-height: 1;
    border: 1px solid #28a745;
    color: #28a745;
    padding: 0.5rem 1.5rem;
    border-radius: 0.25rem;
}

.card {
    margin-bottom: 1.875em;
    border-radius: 5px;
    padding: 0;
    border: 0px solid transparent;
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 8%);
    box-shadow: 0 0 20px rgb(0 0 0 / 8%);
}

table.table {
    margin-bottom: 0;
}

table .table>thead>tr>th {
    font-weight: 600;
    font-size: 12px;
    color: #000;
    text-transform: capitalize;
}

/* media query starts here */
@media(max-width:991px) {}

@media(max-width:767px) {
    .resetBtns label {
        display: none;
    }
}