/* .social_grid.youtube{
  
} */

.bg_white {
	background-size: cover;
	background-position: right;
}

.social_image img {
	width: 108px;
	margin: auto;
	height: 24px;
	object-fit: contain;
}

.vector {
	color: #242833;
	width: 12px;
}

.text-total {
	font-size: 14px;
}


.social_images img {
	display: flex;
	align-items: center;
	min-width: 110px;
	margin: auto;
	height: 24px;
	object-fit: contain;
}

.accordian_bg {
	align-items: center;
	background: #fff;
	border-radius: 8px;
	/* box-shadow: 0 0 14px 0 #e9ebf1; */
	flex-grow: 0;
	justify-content: space-between;
	margin-top: 24px;
	padding: 11px 16px;
	position: relative;
	margin-bottom: 10px;
}

.social_grid,
.gradient_bg {
	text-align: center;
}

.result-search {
	display: flex;
	flex-direction: column;
}

.gradient_bg>div {
	background: #fff;
	position: relative;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	flex-grow: 0;
	margin-top: 24px;
	padding: 11px 16px;
	border-radius: 8px;
	/* box-shadow: 0 0 14px 0 #e9ebf1; */
}

.select_country_globe h4 {
	font-size: 14px;
}

.text-container {
	display: flex;
	flex-direction: column;
	border: transparent;
	text-align: left;
}

.subTitle-result {
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #242833;
}

.result-social {
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #242833;
}

h4.highlight_color {
	background: #ffdcf2;
	border-radius: 7px;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	letter-spacing: 0.1px;
	color: #000000;
	padding: 1px 5px;
}

.gradient_bg h5 {
	font-size: 12px;
	margin: 10px 0px;
}

.gradient_bg p {
	font-weight: 600;
	font-size: 16px;
}

.cmpaign_reach label {
	margin-bottom: 0;
}

.cmpaign_reach>div {
	align-items: center;
}

.cmpaign_reach label.center_label {
	align-self: center;
}

.cmpaign_reach {
	padding: 30px;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #000;
	/* box-shadow: 0px 3px 5px rgb(0 0 0 / 27%); */
	margin-bottom: 31px;
}

.Roblox_show {
	background: #fff;
	padding: 15px;
	border-radius: 10px;
	/* box-shadow: 0px 4px 6px rgb(0 0 0 / 20%); */
}

.cmpaign_reach label {
	margin: 0px 17px;
	display: inline-block;
	font-size: 12px;
}

.bg_white.select_country_globe {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.content_outer.abstract_filter {
	background: #f4f2f9;
}

.bg_white.select_country_globe>div img {
	align-items: center;
	height: unset;
}

.select_country_globe .social_image img {
	width: 35px !important;
}

.filter_search_universe .bg_white h5 {
	cursor: pointer;
}

.content .reachplanner {
	height: 100%;
	background: #ffffff;
	/* box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5); */
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	overflow: hidden;
}

.add_country_btn_outer {
	align-self: flex-end;
}

.highcharts-title {
	display: none;
}

h3.ranges_list {
	display: flex;
}

h3.ranges_list div {
	display: flex;
	align-items: center;
}

/* h3.ranges_list div p {
	margin: 0px;
	font-size: 11px;
} */

/* .highcharts-point {
	display: none;
} */
.round_chart text.highcharts-title {
	display: block;
	font-family: Montserrat;
	font-size: 36px !important;
}

.add_inventorty_btn {
	margin-top: 26px;
	background-color: transparent;
	width: 100%;
	height: 40px;
	padding: 8px 15px;
	border-radius: 100px;
	border: solid 1.5px #4759f7;
	font-size: 12px;
	font-weight: bold;
	color: #4759f7;


}

.audience_outer_wrapper {
	background: white;
	padding: 15px;
	border: 1px solid #ddd;

	border-radius: 10px;
}

.ar_flag {
	height: 30px;
	width: 30px;
}

.flag_outer {
	display: flex;
	align-items: center;
}

.dropdown-content {
	z-index: 9;
}

.total_audience_outer {
	display: flex;
	/* box-shadow: 5px 5px 5px 8px whitesmoke; */
	padding: 12px;
	min-height: 154px;
	margin-top: 20px;

}

.select_country_container {
	position: relative;
	margin-top: 28px;
	background: #fff;
	flex-grow: 1;
	border-radius: 6px;
}

.dateRangeHome {
	height: 48px !important;
}

.dateRangePaddingHome {
	padding-top: 28px !important;
}

.budget_container {
	display: flex;
	align-items: center;
}

.com_used rect.highcharts-background {
	fill: transparent !important;
}

.select_country_container label.lbl_position.lblndex {
	z-index: 3;
}

.zIndexPriority {
	z-index: 999 !important;
}

.select_country_container label.lbl_position {
    padding-left: 10px;
    margin: 0px;
    padding-top: 5px;
	display: block;
}

.bold_item {
	float: left;
}
.pdf_export {
	background: #4759f7;
    border: transparent;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 8px;
    font-weight: 700;
    position: absolute;
	position: absolute;
    right: -70px;
    bottom: 10px;
}
.pdf_export svg{
	fill: #fff;
}
.heading_argentina {
	text-align: left;
	font-size: 20px;
	font-weight: 700 !important
}

.selcted_audience_container {
	display: flex;
	justify-content: space-between;
	padding: 24px;
	border-radius: 8px;
	/* box-shadow: 0 0 14px 0 #e9ebf1; */
	align-items: center;
	border: 1px solid #ddd;
}

.projection_wrapper {
	
	align-items: center;
	justify-content: space-between;
}

.reach_inner_content {
	padding-right: 20px;
}
.line_divide {
    width: 1.5px;
    height: 40px;
    background-color: #e6e2f0;
    flex: 0 0 1.5px;
}
.audience_inner_contetnt {
	display: flex;
	align-items: center;
}

.total_selected_heading {
	padding-left: 20px;
}

.reach_projection_outer {
	display: flex;
	border-left: 3px solid #edecec;
	padding: 20px;
	align-items: center;

}




.summary p {
    margin: 0;
}

.summary h5 {
    margin: 0px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
}

h3.ranges_list.age_range {
    font-weight: 400;
}

.projection_content {
    display: flex;
    align-items: center;
}

.apps_total_calc {
    /* width: 150px; */
}

.apps_total_calc {
    display: flex;
    align-items: center;
    gap: 10px;
}

.apps_total_calc .bar_color.progress {
    width: 122px;
}

.apps_total_calc h4{
        font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    color: #603c88;

}

.total_selected_calc p {
    margin: 0;
    font-size: 12px;
}

.line_divide {
    width: 1.5px;
    height: 40px;
    background-color: #e6e2f0;
    flex: 0 0 1.5px;
}

.reachFooter ul {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: 0px;
    /* margin-bottom: -19px; */
}

.reachFooter p {
    margin: 0px;
}

.reachFooter h4 {
    margin: 0;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
}

.reachFooter {
    padding: 16px 70px 16px 40px;
    background-color: #e6e2f0;
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.total_selected_heading h4 {
	font-size: 22px;
}

.total_selected_heading h2 {
	text-align: left;
}

.reach_inner_content h6 {
	line-height: 17px;
	text-align: left;
	font-size: 11px;

}

.reach_inner_content h2 {
	text-align: left;
}



.cmn_style {
	font-weight: 700 !important;
	font-size: 17px !important;

}

.halfDonut .highcharts-container {
	width: 200px !important;
	margin-right: 20px
}

.halfDonut rect.highcharts-background {
	fill: transparent !important;
}
h4.filter_headings {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    margin-bottom: 24px;
}
.select_country_heading {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
}
.show_btns {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
	cursor: pointer;
}

.delete_icon {
	margin-left: 5px;
	margin-top: 28px;

}

.img_style {
	width: 90%;
	height: 60%
}


.audience_container {
	padding-left: 30px;
	padding-top: 13px;
}

.audience_container h3 {
	font-size: 20px;
	text-align: left;
}

.audience_container p {
	font-size: 12px;
	text-align: left;
}

button.search_btn {
	width: 220px;
	height: 40px;
	border-radius: 100px;
	background-color: #4759f7;
}


div.kite_uni button.search_btn {
	background: rgb(172, 171, 171);
	color: white;
	border-radius: 30px;
	width: 200px;

}

.flag_outer img {
	height: 24px;
	width: 24px;
}

.flag_outer h3 {
	font-size: 12px;
	font-weight: bold;
	font-family: Montserrat;
	margin: 0;
}

.dot_hr_line {
	border: 1px dashed;
	margin-left: 15px;
	margin-right: 15px;
}


.add_country_wrapper {
	display: flex;
	justify-content: space-between;
	padding-left: 15px;
	padding-right: 15px;
}



.add_inventory_btn_outer {
	align-self: end;
}

.social_image.kids_corp_top {
	display: inline-block;
	width: max-content;
}

.social_grid.reachplanner.kids_corp_top {
	padding: 30px 15px;
}

.social_grid.reachplanner.kids_corp_top .social_image.kids_corp {
	width: fit-content;
	background: #ddd;
	padding: 10px;
	margin-left: -15px;
	margin-bottom: 20px;
	background: #dcf2ff;
	border-radius: 0px 30px 30px 0px;
}

.population_heading {
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.1px;
	color: #000000;
}

.gauge_text {
	font-weight: 500;
	font-size: 10px;
	line-height: 17px;
	letter-spacing: 0.1px;

	color: #000000;
}

.social_image.kids_corp img {
	width: 100px;
}

/* .bg_white.select_country_globe .social_image {
	width: 190px;
} */

.social_image {
	text-align: center;
	margin-bottom: 10px;
}

.social_inner_grid {
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 10px;
	text-align: center;
	background: rgb(255 255 255 / 61%);
	min-height: 104px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.social_inner_grid h2 {
	font-size: 12px;
}

.social_inner_grid h5 {
	font-size: 12px;
}

.content_outer.abstract_wrapper {
	background: #f5fbff;
}

.calculaterHeader {
	display: flex;
	background: rgb(65, 79, 177);
	border-radius: 10px;
	align-items: center;
	margin-bottom: 15px;
}

.calculaterHeader h6 {
	color: #fff;
	background-color: #0e1a77;
	margin: 0;
	border-radius: 10px;
	padding: 10px 20px;
}

.calculaterHeader img {
	width: 33px;
}

.calculaterHeader .rmsc.multi-select {
	min-width: 230px;
	margin: 0px 8px;
}

.graph_grid img {
	width: 33px;
}

.white_bg {
	background-color: #fff;
}

.calculaterHeader {
	display: flex;
	background: rgb(65, 79, 177);
	border-radius: 10px;
}

.calculaterHeader h6 {
	color: #fff;
	background-color: #0e1a77;
	margin: 0;
	border-radius: 10px;
	padding: 10px 20px;
}

.calculaterHeader img {
	width: 33px;
}

.name-country {
	margin: 6px 0 6px 12px;
	font-family: Montserrat;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #242833;
}

.calculaterHeader .rmsc.multi-select {
	min-width: 230px;
	margin: 0px 8px;
}

.gradient_bg img.icon {
	width: 33px;
}

.graph_grid.white_bg h4 {
	background-color: rgb(249, 120, 200);
	padding: 10px;
	border-radius: 10px;
	color: #fff;
	text-align: center;
}

p.value {
	font-size: 12px;
	text-align: center;
	padding: 30px 0px;
}

.circle_bar {
	height: 136px;
}

.circle_bar .CircularProgressbar .CircularProgressbar-text {
	font-size: 6px;
	fill: #000;
}

svg.CircularProgressbar {
	height: 260px;
}

.donald_header {
	display: flex;
	align-items: center;
	background: rgb(65, 79, 177);
	border-radius: 10px;
	padding: 5px 1em;
}

.donald_header form {
	display: flex;
	margin-left: auto;
}

.donald_header h4 {
	color: #fff;
	margin: 0;
}

.donald_header button.btn.btn-outline-success {
	border-color: #fff;
	color: #fff;
}

.header_multiselects {
	display: flex;
	margin-left: auto;
}

.total_population {
	background: #fff;
	/* box-shadow: 0px 0px 5px rgba(0, 0, 0, 20%); */
	padding: 5px;
	position: absolute;
	top: 10px;
	z-index: 2;
	display: flex;
	align-items: center;
	border-radius: 5px;
}

.pie_charts {
	position: relative;
}

.total_population span {
	width: 10px;
	height: 10px;
	background: #4caf50;
	display: inline-block;
}

.graph {
	background: #f2f9ff;
	border-radius: 15px;
	overflow: hidden;
}

/* rect.highcharts-point {
	border-radius: 15px;
} */

g.highcharts-series-group {
	border-radius: 15px;
}

.content h2.graph_title {
	font-weight: bold;
	font-size: 16px;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #242833;
	margin: 20px 0 40px 0;
}

.total_population p {
	margin: 0;
	margin-left: 6px;
}

.gradient_bg {
	position: relative;
	border-radius: 15px;
}

.gradient_bg div.social_image {
	background: transparent;
}

.gradient_bg.not_selected::before {
	content: "";
	/* background: linear-gradient(180deg, #3f62db 0%, #fa78c9 100%); */
	position: absolute;
	top: -3px;
	left: -3px;
	width: calc(100% + 6px);
	height: calc(100% + 6px);
	border-radius: 12px;
	filter: blur(2px);
}

/* .gradient_bg.not_selected div {
	background: #eef5fc;
} */

/* .total_population {} */

.total_population {
	background: #fff;
	/* box-shadow: 0px 0px 5px rgba(0, 0, 0, 20%); */
	padding: 5px;
	position: absolute;
	top: 10px;
	z-index: 2;
	display: flex;
	align-items: center;
}

.pie_charts {
	position: relative;
}

.total_population span {
	width: 10px;
	height: 10px;
	background: #4caf50;
	display: inline-block;
}

.total_population p {
	margin: 0;
	margin-left: 6px;
	font-weight: 600;
}

button.rest_all {
	border: 1px solid #fff;
	background: transparent;
	color: #fff;
	margin-right: 6px;
	border-radius: 3px;
	transition: ease all 0.3s;
	-webkit-transition: ease all 0.3s;
}

button.rest_all:hover {
	background: #fff;
	color: #000;
	transition: ease all 0.3s;
	-webkit-transition: ease all 0.3s;
}

/* 12 dec css  */
ul.nav.tabs_outer_wrapper.nav-tabs {
	justify-content: left;
	align-items: center;
	border: transparent;
}

ul.nav.tabs_outer_wrapper.nav-tabs {
	justify-content: center;
	border: transparent;
	/* width: 800px;
	margin-left: 10%; */
}

/* .container-subtitle{
	display: flex;
} */

.tabs_outer_wrapper ul {
	display: flex;
}

.social_grid,
gradient_bg {
	background: #eeeeee;
	border-radius: 15px;
}

.tabs_outer_wrapper .nav-link {
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.1px;
	text-align: center;
	color: #3948ca;
	width: 475px;
	display: block;
	height: unset;
	border: unset;
	padding: 15px 30px;
}

.tab-content .rmsc .dropdown-container {
	border: transparent;
}

.tab-content .rmsc.multi-select .dropdown-heading,
.kite_uni .form-select,
.kite_uni .form-control {
	height: 30px;
	color: #000;
	background-color: #ffffff;
	/* box-shadow: 0px 10px 60px rgba(226, 236, 249, 6.5); */
	border-radius: 12px;
	border: unset;
}

/* .dropdown-heading-value {
	padding-top: 20px;
} */

.select_country_container select.form-select {
	/* padding-top: 20px; */
	padding-left: 10px;
}

/* select.form-control {
	padding-top: 20px;
} */

.select_country_container input {
	/* padding-top: 20px; */
	padding-left: 10px;
}
.reach_filter_wrapper {
    padding: 16px;
    /* box-shadow: 0 0 14px 0 #e9ebf1; */
    background-color: #ebe8f1;
    border-radius: 8px;
}


h3.ranges_list.age_range {
    font-weight: 400;
}
.summary p {
    margin: 0;
}

.summary h5 {
    margin: 0px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
}


button.reset_button_color {
	min-width: 36px;
	box-shadow: unset;
	font-family: Montserrat;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	background-color: transparent;
	color: #4759f7;
}

.setbuttons {
	gap: 10px;
	justify-content: flex-end;
	margin-top: 26px;
}

.kite_uni label {
	margin-bottom: 10px;
	color: #000;
}

ul.tabs_outer_wrapper.nav.nav-tabs {
	padding: 7px;
}

ul.tabs_outer_wrapper.nav-tabs .nav-link.active {
	/* font-size: 14px; */
	background-image: linear-gradient(to right, #793c88 0%, #3948ca 100%);
	background-color: transparent;
	color: #fff;
	position: relative;
	text-decoration: none;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: 0.1px;
	text-align: center;
	/* width: 465px;
	height: 37px; */
	border-radius: 32px;
	/* margin-right: 150px; */
}

ul.tabs_outer_wrapper li {
	width: 50%;
}

ul.tabs_outer_wrapper.nav-tabs .nav-link {
	width: 100%;
}

/* ul.tabs_outer_wrapper.nav-tabs .nav-link.active::before {
	font-size: 14px;
	position: absolute;
	content: "";
	top: auto;
	left: 0;
	right: 0;
	bottom: -1.6px;
	background: linear-gradient(90.07deg, #3f62db 0.06%, #fa78c9 99.28%);
	border-radius: 10px;
	width: 100%;
	height: 3px;
	box-shadow: 0px 0px 6px rgb(241 119 202 / 46%);
	z-index: 2;
} */

.display-none {
	display: none;
}

.display-block {
	display: block;
	margin: 10px 0 5px 0;
}

.result-graf {
	border-radius: 8px;
	/* width: 863px;
	padding: 10px; */
}

.result-grafRo {
	border-radius: 8px;
	/* width: 863px;
	padding: 10px; */
}

.result-grafKid {
	border-radius: 8px;
	/* width: 863px;
	padding: 10px; */
}

.tabs_outer_wrapper.nav-tabs .nav-link:hover {
	border: unset;
	isolation: unset;
}

/* ul.tabs_outer_wrapper.nav-tabs li {
	margin: 0px 10px;
} */

ul.tabs_outer_wrapper.nav-tabs .nav-link.active:focus-visible,
ul.tabs_outer_wrapper.nav-tabs .nav-link:focus-visible {
	border: unset;
}

.kite_uni button.cmn_btn {
	background: #10163a;
	/* box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5); */
	border-radius: 12px;
	color: #fff;
	width: 100%;
	height: 45px;
	font-size: 16px;
	text-align: center;
	display: block;
}

.text-world {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.world-space {
	margin-right: 4px;
}

.space-population {
	margin-left: 5px;
	font-weight: bold;
}

.tab-size {
	width: 950px;
	margin-top: 40px;
}

/* .kite_uni {
	border-top: 2px solid rgba(207, 207, 207, 0.37);
	z-index: 1;
	position: relative;
	padding: 20px;
	border-bottom: 2px solid rgba(207, 207, 207, 0.37);
} */

.connected_population {
	padding: 15px;
	overflow: hidden;
}

.kidscrop_show {
	background: #fff;
	padding: 15px;
	color: #4caf50;
	border-radius: 10px;
	/* box-shadow: 0px 4px 6px rgb(0 0 0 / 20%); */
}

.filter_search_universe {
	background: #fff;
	padding: 15px;
	border-radius: 8px;
	/* box-shadow: 0 0 14px 0 #e9ebf1; */
}

.tab-lg {
	display: flex;
	justify-content: center;
	width: 100%;
	/* height: 15px; */
	/* margin: 10px; */
	border-radius: 32px;
}

.structure {
	display: flex;
	justify-content: center;
}

.tab-size>title {
	color: #4caf50;
}

.filter-universe {
	display: flex;
	flex-direction: row;
}
.pdf_head{
background-color: #f4f2f9;
}
/* 17 august */
p.abs_badge {
	width: 122px;
    height: 24px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 9px 4px;
    background-color: #7209b7;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    text-align: center;
}
.projection_content {
    display: flex;
    align-items: center;
	gap: 10px;
}
.content h2.projectionTitle {
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	text-align: left;
}
.content h2.header_title:first-child {
    font-weight: 700;
}



.summary ul {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 16px 24px;
    border-radius: 8px;
  border: 1px solid #ddd;
    background-color: #fff;
    margin: 45px 0px;
}

.summary p {
    margin: 0;
}

.summary h5 {
    margin: 0px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
}

h3.ranges_list.age_range {
    font-weight: 400;
}

.projection_content {
    display: flex;
    align-items: center;
}

.apps_total_calc {
    /* width: 150px; */
}

.apps_total_calc {
    display: flex;
    align-items: center;
    gap: 10px;
}
.total_selected_calc p {
    margin: 0;
    font-size: 12px;
}
.apps_total_calc .bar_color.progress {
    width: 122px;
    background-image: unset;
    background: #f7e0ec;
}
.apps_total_calc .progress-bar::after {
    background-image: linear-gradient(to right, #f978c8 6%, #633e89 100%);}
.apps_total_calc h4{
        font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    color: #603c88;

}



.projection_wrapper {
	display: flex;
}

/* .round_chart .highcharts-container {
	width: 207px !important;
	flex: 0 0 200px !important;
	margin-right: 20px;
	height: 109px !important;
} */

div#highcharts-mnvocad-108 {
	margin: 0;
}

/* .round_chart rect.highcharts-background {
	fill: transparent !important;
} */

/* .round_chart {
	margin-right: 10px;
} */

.totoal_wrapper {
	display: flex;
	align-items: center;
	gap: 12px;
}

.projectionTotal h3 {
	font-size: 20px;
	font-weight: normal;
	text-align: left;
}

.projectionTotal h4 {
	font-size: 24px;
	font-weight: bold;
	text-align: left;

}

.activity_card {
	display: flex;
	align-items: center;
	gap: 8px;
	white-space:nowrap;

}

.long_divider {
	width: 1.5px;
	background: #dddddd;
	flex: 0 0 1.5px;
	background-color: #dddddd;
	position: relative;
	min-height: 131px;
	height: 100%;
	max-height: 100%;
}

.atcivityTiming h3 {
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	margin-bottom: 0px;
}

.atcivityTiming p {
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	margin-bottom: 0px;
}

.offline ul li {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: flex-end;
	margin-bottom: 10px;
}

.offline ul li:last-child {
	margin-bottom: 0px;
}

.top_activitesWrappers h5,
.com_used h3 {
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	margin-bottom: 15px;
}

.passion_point ul {
	display: flex;
	justify-content: space-between;
	gap: 15px;
	align-items: center;
	margin: 0px;
}

.horizontal_line {
	margin-left: 15px;
	margin-right: 15px;
}

.passion_point ul li {
	display: flex;
	align-items: center;
	gap: 10px;
}

.selcted_audience_container.audience_data {
	background-color: #f4f2f9;
}

.passion_point ul li h2 {
	font-size: 14px;
	font-weight: bold;
	margin: 0px;
}

.passion_point ul li p {
	font-size: 12px;
	font-weight: normal;
	margin-bottom: 0px;
}

.passion_point {
	border-radius: 8px;
	background-color: #e6e2f0;
	padding: 12px 24px;
	margin-top: 15px;
}

.offline .bar_color.progress {
	width: 60px;
}

.offline li p {
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	margin: 0px;
	text-align: right;
	width: 100%;
}

.offline strong {
	font-size: 11px;
}

.top_activites {
	display: flex;
	gap: 26px;
}

.audience_stat {
	border-right: 1.5px solid #ddd;
	padding-right: 24px;
}

.offline .progress-bar::after {
	background-image: unset;
	background-color: #f978c8;
}

.offline .bar_color.progress {
	background: #e6e2f0;
}

.activity_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;
}

.offline h3 {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 8px;
}

.reach_calculater_tabs {
	width: 80%;
	margin: auto;
}

/*  */
/* media query starts here */
@media (max-width: 1499px) {
	.reach_calculater_tabs {
		width: 95%;
		margin: auto;
	}

	.atcivityTiming h3 {
		font-size: 18px;

	}
	.projection_content{
		max-width: 480px;
	}
}

@media (max-width: 1440px) {




	.social_grid,
	.graph_grid {
		padding: 12px;
	}

	.content {
		padding: 1.3em;
	}
}

@media (max-width: 1399px) {
	.reach_calculater_tabs {
		width: 97%;
		margin: auto;
	}
}

@media (max-width: 1199px) {
	.social_inner_grid {
		margin-bottom: 20px;
	}

	.social_image img {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.social_image img {
		width: 50%;
	}

	.calculaterHeader h6 {
		margin: 10px;
		width: 100%;
	}

	.calculaterHeader {
		flex-wrap: wrap;
	}

	.header_multiselects {
		margin: 10px;
		flex-wrap: wrap;
		width: 100%;
	}

	.calculaterHeader .rmsc.multi-select {
		min-width: auto;
		width: 100%;
	}

	.header_multiselects>div {
		width: 100%;
	}
}