.header_outer {
    background: #fff;
    border-bottom: 1px solid #e8e9ed;
    -webkit-box-shadow: none;
    box-shadow: none;
    clear: both;
    padding: 10px 30px;
    position: fixed;
    left: 280px;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
}
.agency_users {
    cursor: pointer;
    font-weight: 600;
}
.left_content div li {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.togle_bar {
    display: block;
    text-align: right;
    width: 10%;
    margin-top: 10px;
}

.sidebar_inner ul>li>a {
    color: #c2c6dc;
    display: inline-block;
    font-size: 12px;
    line-height: 26px;
    padding: 10px 15px;
    margin: 3px 0px;
    position: relative;
    width: 100%;
    text-decoration: none;
    display: flex;
}

header.header_outer a>img {
    max-width: 134px;
}

header.header_outer {
    display: flex;
    align-items: center;
    z-index: 9;
}

.left_content {
    display: flex;
    align-items: center;
}

.left_content a {
    color: #000;
    display: inline-block;
    margin-right: 10px;
}

.left_content a svg {
    font-size: 18px;
}

.left_content ul {
    margin: 0;
}

.right_content button {
    background: transparent;
}

header.header_outer .show>.btn-success.dropdown-toggle,
header.header_outer .btn-success.dropdown-toggle {
    background: transparent;
    border: transparent;
    width: unset;
}

.right_content .dropdown-menu.show a {
    color: #607d8b;
}

header.header_outer .right_content img {
    width: 40px;
    height: 40px;
}

.right_content .show>.btn-success.dropdown-toggle:focus,
.right_content .btn-success.dropdown-toggle:focus,
.right_content.btn-success:focus {
    box-shadow: unset;
}

.dropdown-menu.show a.dropdown-item:hover {
    color: #000;
}

.show.dropdown .form-control {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 20%);
}

.dropdown-menu.show a {
    /* color: #607d8b; */
    display: block;
    font-size: 12px;
    line-height: 22px;

}

.user_dropdown.dropdown span {

    margin-right: 10px;
    font-size: 12px;
}

.select_language.dropdown .dropdown-menu {
    position: relative !important;
    inset: unset !important;
    transform: unset !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user_dropdown .select_language.dropdown span {
    font-size: 12px;
    color: #607d8b;
    font-weight: 400;
}

.user_dropdown .dropdown-menu {
    min-width: 199px;
}

.dropdown-menu.show {
    -webkit-box-shadow: 0 3px 12px rgb(0 0 0 / 5%);
    box-shadow: 0 3px 12px rgb(0 0 0 / 13%);
    border: transparent;
}

.right_content {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.notifications span {
    background: green;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 6px;
    border: 2px solid #FFF;
}

.notifications svg {
    font-size: 18px;
}

button#dropdown-basic {

    border-radius: 13px;
    padding: 5px 12px;
    display: flex;
    align-items: center;
}

p.designation {
    margin: 0;
    letter-spacing: 0.1px;
    color: #8A8C8F;
    font-weight: 500;
    font-size: 10px;
    text-align: left;
}

.arrow__icon {
    flex: 0 0 25px;
}

.arrow__icon svg {
    stroke: #000;
}

.right_content button#dropdown-basic span {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
}

.notifications {
    height: 32px;
    position: relative;
    width: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    margin-right: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
    background: transparent;
}

a {
    text-decoration: none;
}

.dropdown-menu.show {
    top: 10px !important;
}

.user_dropdown.dropdown span {
    color: #000;
    font-size: 12px;
}