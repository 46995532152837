/* media plan css  */
/* campaign_saas css */
.cmn__filters ul li.budget_wrapper {
    width: unset;
    margin-left: auto;
}

.creativesimg video {
    height: 122px;
}

.budget_wrapper li.total_used {
    display: flex;
    width: unset;
    gap: 24px;
}

li.budget_wrapper ul {
    margin: 0;
}

.flex-column.nav.nav-pills {
    display: flex;
    flex-direction: row;
}

.cmn_tabs.nav.nav-pills {
    display: flex;
    justify-content: flex-end;
}

.cmn_tabs.nav.nav-pills a.active {
    background: transparent;
    color: #282c31;
}

.cmn_tabs.nav.nav-pills a {
    background: transparent;
    color: #282c31;
}

ul.budget.buget_wraper li {}

.owner_filter.owners_drop_down.dropdown {
    height: unset;
}

.header__media li.select_email_dropdwon strong {
    font-weight: 700;
}

.select_email_dropdwon .owner_filter {
    min-width: 200px;
}

.owner_filter.dropdown.owners_drop_down button#dropdown-basic {
    background: #f6f7f9;
    border-color: #f6f7f9;
    color: #000;
}

ul.budget.buget_wraper.buget_wraper_outer {}

button#dropdown-basic::after {
    display: none;
}

ul.budget.buget_wraper li p {
    margin: 0px;
    font-weight: 500;
}

.budget_wrapper li.progress_result>div:first-child {
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: 108px;
}

/* .saas_modal.modal-body input, .saas_modal.modal-body select {
    background-color: #f6f7f9;
    border-radius: 8px;
    height: 48px;
    font-size: 14px;
    margin-bottom: 20px;
    box-shadow: unset;
    color: #242833;
} */
.saas_modal.modal-body input::placeholder {
    color: #242833;

}

.saas_modal.modal-body button.cmn_btn.cancel_cmn {
    background: #989fb3;
}

.saas_modal.modal-body button.cancel_cm_btn {
    color: #989fb3;
}

.multiSelectContainer {
    position: static;
    text-align: left;
    width: 100%;
}

/* custom checkbox */
.sass_checkbox input[type="checkbox"] {
    position: relative;
    width: 32.2px;
    height: 20px;
    -webkit-appearance: none;
    appearance: none;
    background: #eee6f3;
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
}

.sass_checkbox input[type="checkbox"]::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #6750a4;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: 0.5s;
}

.sass_checkbox input[type="checkbox"]:checked::before {
    transform: translateX(12px);
    background: #6750a4;
}

.sass_checkbox input[type="checkbox"]:checked {
    background: #00ed64;
}

.saas_modal.modal-body .sass_checkbox input {
    height: 20px;
    background: #f6f2f9;
    border: solid 1px #eee6f3;
    border-radius: 35px;
    width: 32.2px;
}

.form-group.sass_checkbox label {
    color: #242833;
}

.form-group.sass_checkbox {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-top: 12px;
}

.saas_modal .model_btn {
    border-top: 1px solid #e9ebf1;
    padding-top: 24px;
    margin-top: 3px;
}

.access_report h5 {
    color: #242833;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 16px;
}

.access_report {
    margin-top: 4px;
}

.saas_info .advert_tabel .media_plan_info_list ul {
    padding: 10px;
}

.saas_info ul.budget {
    justify-content: space-between;
}

.modal-body button.cmn_btn.cmn_bordered_btn {
    background: transparent;
    color: #4759f7;
    border: 1.5px solid #4759f7;
    padding: 7px 40px;
    height: 40px;
}

.client_rest {
    text-align: right;
    margin-top: 6px;
    margin-bottom: 20px;
}

ul.budget.single_budget.line_item_buget {
    padding: 16.5px 16px;
}

.rates_list.saas_rates {
    justify-content: unset;
    gap: 50px;
}

.status_badge p span {
    font-weight: 400;
    color: #000;
}

ul.budget.single_budget.creative_buget li.progress_result {
    padding: 13px 0px;
}

.status_dropdown .btn:first-child:active {
    background-color: unset;
    color: #000;
}

.saas_modal {
    padding: 24px;
}

.saas_mediaplan_table .filter_btn span,
.saas_mediaplan_table .status_design a.dropdown-item {
    font-weight: normal;
}

.model_btn.model_btn_new_style {
    text-align: right;
    display: flex;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
}

button.cancel_cm_btn.delete_cmn_btn.delete_pink_btn {
    padding: 11px 40px;
    border-radius: 35px;
    height: unset;
    box-shadow: unset;
    font-size: 12px;
    font-weight: bold;
    background: #ff79c9;
    color: #fff;
    min-width: 220px;
}

label.lbl_position {
    z-index: 5 !important;
}

.advertiserListId {
    padding-left: 16px !important;
}

.placeholderWhite::placeholder {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.paddingCancelButton {
    padding-right: 32px !important;
}

/* campaign_saas css */
@media(max-width:1499px) {
    ul.budget li {
        gap: 5px;
    }

    li.progress_result {
        flex-wrap: wrap;
    }

    .saas_info li.progress_result {
        flex-wrap: unset;
    }

    li.progress_result>div {
        width: 103px;
    }
}